import React, { useEffect, useState } from "react";
import LabeledInput, {
  LabelModes,
} from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import ContentContainer from "../../../templates/content-container/contentContainer";
import "./manifestDocuments.scss";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadDocumentsByManifest,
  invoiceDocumentsSelector,
  resetInvoiceDocuments,
} from "../../../slices/invoice-documents/invoiceDocumentsSlice";
import { toast } from "react-toastify";
import { ManifestType } from "../../../app/data/invoice/models";

function isPositiveInteger(str: string) {
  return str === "" || /^[1-9][0-9]*$/.test(str);
}

export const ManifestDocuments = () => {
  const [manifestNumber, setManifestNumber] = useState<string>("");
  const [types, setTypes] = useState<Set<string>>(new Set());
  const invoiceDocumentsState = useSelector(invoiceDocumentsSelector);
  const dispatch = useDispatch();
  const [manifestType, setManifestType] = useState(ManifestType.LINEHAUL);
  const [formError, setFormError] = useState("");
  const manifestNumberRegex = /^[0-9]{3,6}$/;
  
  const onStartOver = () => {
    dispatch(resetInvoiceDocuments());
    setManifestNumber("");
    setTypes(new Set());
    setManifestType(ManifestType.LINEHAUL)
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return;
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onExportFailed = (error: string) => {
    toast.error(error);
  };

  const onSubmit = (isConsolidated: boolean) => {
    if (Number.parseInt(manifestNumber) === 0 || !manifestNumberRegex.test(manifestNumber)) {
      setFormError("Please enter valid manifest number");
      return;
    }
    setFormError("");
    dispatch(
      downloadDocumentsByManifest(
        {
          manifestNumber: Number(manifestNumber),
          types: Array.from(types.values()),
          manifestType,
          isConsolidated
        },
        onExportSuccess,
        onExportFailed
      )
    );
  };

  const onDownloadAgain = () => {
    if (!invoiceDocumentsState.link) return;
    onExportSuccess(invoiceDocumentsState.link);
  };

  const onSelectType = (checked: boolean, type: string) => {
    setTypes((currentTypes) => {
      const newTypes = new Set(currentTypes);
      if (checked) {
        newTypes.add(type);
      } else {
        newTypes.delete(type);
      }
      return newTypes;
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceDocuments());
    };
  }, [dispatch]);

  return (
    <ContentContainer isLoading={invoiceDocumentsState.requestStarted} title="Manifest Documents">
      {!invoiceDocumentsState.requestSucceed && (
        <div className="xgs-manifest-documents">
          <div className="xgs-manifest-documents__radio-group">
            <label htmlFor="linehaul">Linehaul</label>
            <input
              checked={manifestType === ManifestType.LINEHAUL}
              id="linehaul"
              type="radio"
              name="search-by"
              onClick={(e) => setManifestType(ManifestType.LINEHAUL)}
            />
            <label htmlFor="delivery">Delivery</label>
            <input
              checked={manifestType === ManifestType.DELIVERY}
              type="radio"
              id="delivery"
              name="search-by"
              onClick={(e) => setManifestType(ManifestType.DELIVERY)}
            />
          </div>
          <LabeledInput
            className="xgs-manifest-documents__label"
            labelMode={LabelModes.column}
            label="Manifest number:"
          >
            <input
              className="xgs-manifest-documents__manifest xgs-input xgs-input__field"
              value={manifestNumber}
              placeholder="Manifest number"
              maxLength={6}
              onChange={(e) =>
                isPositiveInteger(e.target.value) &&
                setManifestNumber(e.target.value)
              }
            />
          </LabeledInput>

          <div className="xgs-manifest-documents__checkboxes">
            <XGSCheckbox
              mods={{ reverse: true }}
              name="BOL"
              onChange={(e) => onSelectType(e.target.checked, "BOL")}
              checked={types.has("BOL")}
            >
              BOL
            </XGSCheckbox>

            <XGSCheckbox
              mods={{ reverse: true }}
              name="POD"
              onChange={(e) => onSelectType(e.target.checked, "POD")}
              checked={types.has("POD")}
            >
              POD
            </XGSCheckbox>

            <XGSCheckbox
              mods={{ reverse: true }}
              name="INV"
              onChange={(e) => onSelectType(e.target.checked, "INV")}
              checked={types.has("INV")}
            >
              Invoice
            </XGSCheckbox>

            <XGSCheckbox
              mods={{ reverse: true }}
              name="ATH"
              onChange={(e) => onSelectType(e.target.checked, "ATH")}
              checked={types.has("ATH")}
            >
              Authorization
            </XGSCheckbox>

            <XGSCheckbox
              mods={{ reverse: true }}
              name="MSC"
              onChange={(e) => onSelectType(e.target.checked, "MSC")}
              checked={types.has("MSC")}
            >
              Misc
            </XGSCheckbox>  
          </div>
          {formError && (
            <div className="xgs-manifest-documents__error">{formError}</div>
          )}
          <div className="xgs-manifest-documents__buttons">
            <Button
              disabled={invoiceDocumentsState.requestStarted || types.size === 0 || manifestNumber.length === 0}
              onClick={() => onSubmit(true)}
              className="xgs-manifest-documents__buttons__submit"
              theme={ButtonThemes.blue}
            >
              PDF File
            </Button>
            <Button
              disabled={invoiceDocumentsState.requestStarted || types.size === 0 || manifestNumber.length === 0}
              onClick={() => onSubmit(false)}
              className="xgs-manifest-documents__buttons__submit"
              theme={ButtonThemes.blue}
            >
              ZIP File
            </Button>
          </div>
        </div>
      )}
      {invoiceDocumentsState.requestSucceed && (
        <div className="xgs-shipments-documents__success">
          <div>
            Your download will start shortly!
            <br />
            If not, try this{" "}
            <span
              className="cursor-pointer blue-link"
              onClick={onDownloadAgain}
            >
              direct download link
            </span>
            .
          </div>
          <Button
            className="xgs-shipments-documents__success__start-over-button"
            theme={ButtonThemes.blue}
            onClick={onStartOver}
          >
            Start Over
          </Button>
        </div>
      )}
    </ContentContainer>
  );
};
