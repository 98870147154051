import agingInvoicesPaymentReducer from "./aging/agingInvoicesPaymentSlice";
import agingInvoicesReducer from "./aging/agingInvoicesSlice";
import agingReducer from "./aging/agingSlice";
import announcementReducer from "./announcement/announcementSlice";
import AnnouncementState from "./announcement/AnnouncementState";
import bolReducer from "./bol/bolSlice";
import BolState from "./bol/BolState";
import changeUserPasswordReducer from "./user/changeUserPasswordSlice";
import ChangeUserPasswordState from "./user/ChangeUserPasswordState";
import claimsReducer from "./claims/claimsSlice";
import ClaimsState from "./claims/ClaimsState";
import commonReducer from "./common/commonSlice";
import CommonState from "./common/CommonState";
import companyReducer from "./company/companySlice";
import CompanyState from "./company/CompanyState";
import companySettingsAccountsReducer from "./company-settings/companySettingsAccountsSlice";
import CompanySettingsAccountsState from "./company-settings/CompanySettingsAccountsState";
import companySettingsShippersReducer from "./company-settings/companySettingsShippersSlice";
import CompanySettingsShippersState from "./company-settings/CompanySettingsShippersState";
import companySettingsUsersReducer from "./company-settings/companySettingsUsersSlice";
import CompanySettingsUsersState from "./company-settings/CompanySettingsUsersState";
import createTeamReducer from "./customers/teams/createTeamSlice";
import CreateTeamState from "./customers/teams/CreateTeamState";
import customerPickupReducer from "./customer-pickup/customerPickupSlice";
import CustomerPickupState from "./customer-pickup/customerPickupState";
import featureBannerReducer from "./feature-banner/featureBannerSlice";
import FeatureBannerState from "./feature-banner/FeatureBannerState";
import feedbackReducer from "./feedback/feedbackSlice";
import FeedbackState from "./feedback/FeedbackState";
import legacyUserReducer from "./user/legacyUserSlice";
import LegacyUserState from "./user/LegacyUserState";
import exportReducer from "./export/exportSlice";
import ExportState from "./export/ExportState";
import filtersToggleReducer from "./filters-toggle/filtersToggleSlice";
import FiltersToggleState from "./filters-toggle/FiltersToggleState";
import forgotPasswordReducer from "./user/forgotPasswordSlice";
import ForgotPasswordState from "./user/ForgotPasswordState";
import fuelSurchargeReducer from "./fuel-surcharge/fuelSurchargeSlice";
import FuelSurchargeState from "./fuel-surcharge/FuelSurchargeState";
import GeneralSettingsState from "./general-settings/GeneralSettingsState";
import inventoryReducer from "./warehouse/inventory/inventorySlice";
import InventoryState from "./warehouse/inventory/InventoryState";
import inviteManagementReducer from "./invite-management/inviteManagementSlice";
import InviteManagementState from "./invite-management/InviteManagementState";
import Invoice, { InvoiceDetails } from "../app/data/invoice/models";
import invoiceDetailsReducer from "./invoice/invoiceSlice";
import invoiceDocumentsReducer from "./invoice-documents/invoiceDocumentsSlice";
import InvoiceDocumentsState from "./invoice-documents/InvoiceDocumentsState";
import invoiceRecordsReducer from "./invoice-records/invoiceRecordsSlice";
import InvoiceRecordsState from "./invoice-records/InvoiceRecordsState";
import JWTGeneratorReducer from "./jwt-generator/JWTGeneratorSlice";
import JWTGeneratorState from "./jwt-generator/JWTGeneratorState";
import metricsReducer from "./metrics/metricsSlice";
import MetricsState from "./metrics/MetricsState";
import notificationsReducer from "./notifications/notificationsSlice";
import NotificationsState from "./notifications/NotificationsState";
import paymentReducer from "./payment/paymentSlice";
import PaymentState from "./payment/PaymentState";
import pendingRequestsReducer from "./customers/pending/pendingRequestsSlice";
import PendingRequestsState from "./customers/pending/PendingRequestsState";
import pickupReducer from "./pickup/pickupSlice";
import PickupState from "./pickup/PickupState";
import pickupAssignmentReducer from "./pickup/pickupAssignmentSlice";
import PickupAssignmentState from "./pickup/PickupAssignmentState";
import podRecognitionReducer from "./documents/podRecognitionSlice";
import PodRecognitionState from "./documents/PodRecognitionState";
import portalRegistrationReducer from "./portal-registration/portalRegistrationSlice";
import PortalRegistrationState from "./portal-registration/PortalRegistrationState";
import profileReducer from "./user/profile/profileSlice";
import ProfileState from "./user/profile/ProfileState";
import publicShipmentDetailsReducer from "./public-shipment-details/publicShipmentDetailsSlice";
import PublicShipmentDetailsState from "./public-shipment-details/PublicShipmentDetailsState";
import ratesReducer from "./rates/ratesSlice";
import RatesState from "./rates/RatesState";
import receiptsReducer from "./warehouse/receipts/receiptsSlice";
import ReceiptsState from "./warehouse/receipts/ReceiptsState";
import recognitionLogsReducer from "./documents/recognitionLogsSlice";
import RecognitionLogsState from "./documents/RecognitionLogsState";
import recordsReducer from "./delivery-records/recordsSlice";
import RecordsState from "./delivery-records/RecordsState";
import reportsReducer from "./reports/reportsSlice";
import ReportsState from "./reports/ReportsState";
import requestRatesReducer from "./rates/requestRatesSlice";
import RequestRatesState from "./rates/RequestRatesState";
import resetPasswordReducer from "./reset-password/resetPasswordSlice";
import ResetPasswordState from "./reset-password/ResetPasswordState";
import returnReducer from "./return/returnSlice";
import ReturnState from "./return/ReturnState";
import serviceCentersReducer from "./service-centers/serviceCentersSlice";
import ServiceCentersState from "./service-centers/ServiceCentersState";
import serviceCentersStatusReducer from "./service-centers-status/serviceCentersStatusSlice";
import ServiceCentersStatusState from "./service-centers-status/ServiceCentersStatusState";
import shipmentDetailsReducer from "./shipment-details/shipmentDetailsSlice";
import ShipmentDetailsState from "./shipment-details/ShipmentDetailsState";
import signupByInviteReducer from "./signup-by-invite/signupByInviteSlice";
import SignupByInviteState from "./signup-by-invite/SignupByInviteState";
import subscriptionsReducer from "./subscriptions/subscriptionsSlice";
import SubscriptionsState from "./subscriptions/SubscriptionsState";
import teamAccountReducer from "./customers/teams/teamAccountSlice";
import TeamAccountState from "./customers/teams/TeamAccountState";
import teamShippersReducer from "./customers/teams/teamShippersSlice";
import TeamShippersState from "./customers/teams/TeamShippersState";
import teamUsersReducer from "./customers/users/teamUsersSlice";
import TeamUsersState from "./customers/users/TeamUsersState";
import trackShipmentReducer from "./track-shipment/trackShipmentSlice";
import TrackShipmentState from "./track-shipment/TrackShipmentState";
import trailerLocationReducer from "./trailer-location/trailerLocationSlice";
import TrailerLocationState from "./trailer-location/TrailerLocationState";
import transactionsReducer from "./warehouse/transactions/transactionsSlice";
import TransactionsState from "./warehouse/transactions/TransactionsState";
import transitCalculatorReducer from "./transit-calculator/transitCalculatorSlice";
import TransitCalculatorState from "./transit-calculator/TransitCalculatorState";
import userReducer from "./user/userSlice";
import UserState from "./user/UserState";
import xgsInlineSwitchReducer from "./xgs-inline-switch/xgsInlineSwitchSlice";
import XgsInlineSwitchState from "./xgs-inline-switch/XgsInlineSwitchState";
import { Aging } from "../app/data/aging/models";
import { combineReducers } from "redux";
import { HolidayCalendarState } from "./holiday-calendar/holidayCalendarState";
import { holidayCalendarReducer } from "./holiday-calendar/holidayCalendarSlice";
import { StandingAppointmentState } from "./customers/standing-appointment/standingAppointmentState";
import { standingAppointmentReducer } from "./customers/standing-appointment/standingAppointmentSlice";
import CompanyAccountDetailsState from "./company-settings/companyAccountDetailsState";
import companyAccountDetailsReducer from "./company-settings/companyAccountDetailsSlice";
import ManifestsState from './manifest/ManifestsState'
import { manifestsReducer } from './manifest/manifestSlice'
import TeamAccountContactState from "./customers/teams/teamAccountContactState";
import teamAccountContactReducer from "./customers/teams/teamAccountContactSlice";
import { AppointmentState } from "./appointments/appointmentsState";
import appointmentReducer from "./appointments/appointmentsSlice";
import generalSettingsReducer from "./general-settings/generalSettingsSlice";
import { ExceptionState } from "./exceptions/exceptionsState";
import { exceptionReducer } from "./exceptions/exceptionsSlice";
import { LinehaulMileageState } from "./linehaul-mileage/linehaulMileageState";
import linehaulMileageReducer from "./linehaul-mileage/linehaulMileageSlice";
import shipperReducer from "./shipper/shipperSlice";
import ShipperState from "./shipper/ShipperState";
import { DispatchCreateRouteState, DispatchEditRouteDetailsState, DispatchPlanProbillsState, DispatchSelectedProbillsState } from "./dispatch-planning/dispatchProbillsState";
import dispatchSelectedProbillsReducer from "./dispatch-planning/dispatchSelectedProbillsSlice";
import dispatchPlanProbillsReducer from "./dispatch-planning/dispatchPlanProbillsSlice";
import dispatchCreateRouteReducer from "./dispatch-planning/dispatchCreateRouteSlice";
import { ActiveRoutesState, AddProbillsToRouteState, AddStopsToRouteState, CreatePicklistFromRouteState, DeleteRouteState, ProbillsToAddToRouteState, RemoveProbillFromRouteState, RemoveStopFromRouteState, RequestRouteOptimizationState, RouteStopsState, StopsToAddToRouteState, UpdateRouteState } from "./dispatch-planning/routes/routesState";
import activeRoutesReducer from "./dispatch-planning/routes/activeRoutesSlice";
import routeStopsReducer from "./dispatch-planning/routes/routeStopsSlice";
import picklistDetailsReducer from "./dispatch-planning/picklists/picklistDetailsSlice";
import { ConvertPicklistToRouteState, PicklistDetailsState, PicklistPdfState, PicklistsLoadingStatusState } from "./dispatch-planning/picklists/picklistsState";
import updateRouteReducer from "./dispatch-planning/routes/updateRouteSlice";
import addProbillsToRouteReducer from "./dispatch-planning/routes/addProbillsToRouteSlice";
import probillsToAddToRouteReducer from "./dispatch-planning/routes/probillsToAddToRouteSlice";
import stopsToAddToRouteReducer from "./dispatch-planning/routes/stopsToAddToRouteSlice";
import addStopsToRouteReducer from "./dispatch-planning/routes/addStopsToRouteSlice";
import removeProbillFromRouteReducer from "./dispatch-planning/routes/removeProbillFromRouteSlice";
import removeStopFromRouteReducer from "./dispatch-planning/routes/removeStopFromRouteSlice";
import requestRouteOptimizationReducer from "./dispatch-planning/routes/optimizeRouteSlice";
import createPicklistFromRouteReducer from "./dispatch-planning/routes/createPicklistFromRouteSlice";
import planningTipsReducer from "./dispatch-planning/planningTips/planningTipsSlice";
import { PlanningTipsState } from "./dispatch-planning/planningTips/planningTipsState";
import picklistPdfReducer from "./dispatch-planning/picklists/picklistPdfSlice";
import convertPicklistToRouteReducer from "./dispatch-planning/picklists/convertPicklistToRouteSlice";
import dispatchEditRouteDetailsReducer from "./dispatch-planning/dispatchEditRouteDetailsSlice";
import deleteRouteReducer from "./dispatch-planning/routes/deleteRouteSlice";
import { UnplannableProbillsState } from "./dispatch-planning/unplannable-probills/unplannableProbillsState";
import unplannableProbillsReducer from "./dispatch-planning/unplannable-probills/unplannableProbillsSlice";
import picklistsLoadingStatusReducer from "./dispatch-planning/picklists/picklistsLoadingStatusSlice";
import DownloadState from "./download/DownloadState";
import downloadReducer from "./download/downloadSlice";
import { WarehouseInventoryState } from "./warehouse-inventory/warehouseInventoryState";
import warehouseInventoryReducer from "./warehouse-inventory/warehouseInventorySlice";

export interface BaseState {
  loading: boolean;
  loadingFailed: boolean;
  loadingError?: string | null;
  loaded: boolean;
}

export interface AgingState extends BaseState {
  data: Aging | null | undefined;
}

export interface AgingInvoicesState extends BaseState {
  invoices: Invoice[];
  needToReload?: boolean;
  lastIds: string | null | undefined;
  loadingPortion: boolean;
  loadedAll: boolean;
  loadingCSV: boolean;
  loadingFailedCSV: boolean;
  invoicesCSV: Invoice[];
}

export interface AgingInvoicesPaymentState {
  selectedInvoiceNumbers?: number[];
}

export interface InvoiceDetailsState extends BaseState {
  invoiceDetails: InvoiceDetails | null | undefined;
  needToReload: boolean | null | undefined;
}

export interface IState {
  activeRoutes: ActiveRoutesState;
  addProbillsToRoute: AddProbillsToRouteState;
  addStopsToRoute: AddStopsToRouteState;
  aging: AgingState;
  agingInvoices: AgingInvoicesState;
  agingInvoicesPayment: AgingInvoicesPaymentState;
  announcement: AnnouncementState;
  appointment: AppointmentState;
  bol: BolState;
  changeUserPassword: ChangeUserPasswordState;
  claims: ClaimsState;
  common: CommonState;
  companyAccountDetails: CompanyAccountDetailsState;
  companySettingsAccounts: CompanySettingsAccountsState;
  companySettingsShippers: CompanySettingsShippersState;
  companySettingsUsers: CompanySettingsUsersState;
  convertPicklistToRoute: ConvertPicklistToRouteState;
  createPicklistFromRoute: CreatePicklistFromRouteState;
  createTeam: CreateTeamState;
  customerPickup: CustomerPickupState;
  deleteRoute: DeleteRouteState;
  dispatchCreateRoute: DispatchCreateRouteState;
  dispatchEditRouteDetails: DispatchEditRouteDetailsState;
  dispatchPlanProbills: DispatchPlanProbillsState;
  dispatchSelectedProbills: DispatchSelectedProbillsState;
  download: DownloadState;
  exception: ExceptionState;
  export: ExportState;
  featureBanner: FeatureBannerState;
  feedback: FeedbackState;
  filtersToggle: FiltersToggleState;
  forgotPassword: ForgotPasswordState;
  fuelSurcharge: FuelSurchargeState;
  generalSettings: GeneralSettingsState;
  holidayCalendar: HolidayCalendarState;
  inventory: InventoryState;
  inviteManagement: InviteManagementState;
  invoiceDetails: InvoiceDetailsState;
  invoiceDocuments: InvoiceDocumentsState;
  invoiceRecords: InvoiceRecordsState;
  jwtGenerator: JWTGeneratorState;
  legacyUser: LegacyUserState;
  linehaulMileage: LinehaulMileageState;
  manifests: ManifestsState;
  metrics: MetricsState;
  notifications: NotificationsState;
  payment: PaymentState;
  pendingRequests: PendingRequestsState;
  picklistDetails: PicklistDetailsState;
  picklistPdf: PicklistPdfState;
  picklistsLoadingStatus: PicklistsLoadingStatusState;
  pickup: PickupState;
  pickupAssignment: PickupAssignmentState;
  planningTips: PlanningTipsState;
  podRecognition: PodRecognitionState;
  portalRegistration: PortalRegistrationState;
  probillsToAddToRoute: ProbillsToAddToRouteState;
  profile: ProfileState;
  publicShipmentDetails: PublicShipmentDetailsState;
  rates: RatesState;
  receipts: ReceiptsState;
  recognitionLogs: RecognitionLogsState;
  records: RecordsState;
  removeProbillFromRoute: RemoveProbillFromRouteState;
  removeStopFromRoute: RemoveStopFromRouteState;
  reports: ReportsState;
  requestRates: RequestRatesState;
  requestRouteOptimization: RequestRouteOptimizationState;
  resetPassword: ResetPasswordState;
  return: ReturnState;
  routeStops: RouteStopsState;
  serviceCenters: ServiceCentersState;
  serviceCentersStatus: ServiceCentersStatusState;
  shipmentDetails: ShipmentDetailsState;
  shipper: ShipperState;
  signupByInvite: SignupByInviteState;
  standingAppointment: StandingAppointmentState;
  stopsToAddToRoute: StopsToAddToRouteState;
  subscriptions: SubscriptionsState;
  teamAccount: TeamAccountState;
  teamAccountContact: TeamAccountContactState;
  teams: CompanyState;
  teamShippers: TeamShippersState;
  teamUsers: TeamUsersState;
  trackShipment: TrackShipmentState;
  trailerLocation: TrailerLocationState;
  transactions: TransactionsState;
  transitCalculator: TransitCalculatorState;
  unplannableProbills: UnplannableProbillsState;
  updateRoute: UpdateRouteState;
  user: UserState;
  xgsInlineSwitch: XgsInlineSwitchState;
  warehouseInventory: WarehouseInventoryState;
}

const appReducer = combineReducers({
  activeRoutes: activeRoutesReducer,
  addProbillsToRoute: addProbillsToRouteReducer,
  addStopsToRoute: addStopsToRouteReducer,
  aging: agingReducer,
  agingInvoices: agingInvoicesReducer,
  agingInvoicesPayment: agingInvoicesPaymentReducer,
  announcement: announcementReducer,
  appointment: appointmentReducer,
  bol: bolReducer,
  changeUserPassword: changeUserPasswordReducer,
  claims: claimsReducer,
  common: commonReducer,
  companyAccountDetails: companyAccountDetailsReducer,
  companySettingsAccounts: companySettingsAccountsReducer,
  companySettingsShippers: companySettingsShippersReducer,
  companySettingsUsers: companySettingsUsersReducer,
  convertPicklistToRoute: convertPicklistToRouteReducer,
  createPicklistFromRoute: createPicklistFromRouteReducer,
  createTeam: createTeamReducer,
  customerPickup: customerPickupReducer,
  deleteRoute: deleteRouteReducer,
  dispatchCreateRoute: dispatchCreateRouteReducer,
  dispatchEditRouteDetails: dispatchEditRouteDetailsReducer,
  dispatchPlanProbills: dispatchPlanProbillsReducer,
  dispatchSelectedProbills: dispatchSelectedProbillsReducer,
  download: downloadReducer,
  exception: exceptionReducer,
  export: exportReducer,
  featureBanner: featureBannerReducer,
  feedback: feedbackReducer,
  filtersToggle: filtersToggleReducer,
  forgotPassword: forgotPasswordReducer,
  fuelSurcharge: fuelSurchargeReducer,
  generalSettings: generalSettingsReducer,
  holidayCalendar: holidayCalendarReducer,
  inventory: inventoryReducer,
  inviteManagement: inviteManagementReducer,
  invoiceDetails: invoiceDetailsReducer,
  invoiceDocuments: invoiceDocumentsReducer,
  invoiceRecords: invoiceRecordsReducer,
  jwtGenerator: JWTGeneratorReducer,
  legacyUser: legacyUserReducer,
  linehaulMileage: linehaulMileageReducer,
  manifests: manifestsReducer,
  metrics: metricsReducer,
  notifications: notificationsReducer,
  payment: paymentReducer,
  pendingRequests: pendingRequestsReducer,
  picklistDetails: picklistDetailsReducer,
  picklistPdf: picklistPdfReducer,
  picklistsLoadingStatus: picklistsLoadingStatusReducer,
  pickup: pickupReducer,
  pickupAssignment: pickupAssignmentReducer,
  planningTips: planningTipsReducer,
  podRecognition: podRecognitionReducer,
  portalRegistration: portalRegistrationReducer,
  probillsToAddToRoute: probillsToAddToRouteReducer,
  profile: profileReducer,
  publicShipmentDetails: publicShipmentDetailsReducer,
  rates: ratesReducer,
  receipts: receiptsReducer,
  recognitionLogs: recognitionLogsReducer,
  records: recordsReducer,
  removeProbillFromRoute: removeProbillFromRouteReducer,
  removeStopFromRoute: removeStopFromRouteReducer,
  reports: reportsReducer,
  requestRates: requestRatesReducer,
  requestRouteOptimization: requestRouteOptimizationReducer,
  resetPassword: resetPasswordReducer,
  return: returnReducer,
  routeStops: routeStopsReducer,
  serviceCenters: serviceCentersReducer,
  serviceCentersStatus: serviceCentersStatusReducer,
  shipmentDetails: shipmentDetailsReducer,
  shipper: shipperReducer,
  signupByInvite: signupByInviteReducer,
  standingAppointment: standingAppointmentReducer,
  stopsToAddToRoute: stopsToAddToRouteReducer,
  subscriptions: subscriptionsReducer,
  teamAccount: teamAccountReducer,
  teamAccountContact: teamAccountContactReducer,
  teams: companyReducer,
  teamShippers: teamShippersReducer,
  teamUsers: teamUsersReducer,
  trackShipment: trackShipmentReducer,
  trailerLocation: trailerLocationReducer,
  transactions: transactionsReducer,
  transitCalculator: transitCalculatorReducer,
  unplannableProbills: unplannableProbillsReducer,
  warehouseInventory: warehouseInventoryReducer,
  updateRoute: updateRouteReducer,
  user: userReducer,
  xgsInlineSwitch: xgsInlineSwitchReducer
});

const rootReducer = (state: any, action: any) => {
  return appReducer((action.type === "FULL_RESET") ? { user: state.user } : state, action);
};

export default rootReducer;
